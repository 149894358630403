.signup-container {
    background: url('/src/images/img-12s.jpg'); 
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 110vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
}


  
  @media screen and (max-width: 960px) {
    .signup-container > h1 {
      font-size: 70px;
      margin-top: 150px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .signup-container > h1 {
      font-size: 50px;
      margin-top: 100px;
    }
  
    .signup-container > p {
      font-size: 30px;
    }
  
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
  
    .btn {
      width: 100%;
    }
  }